const DEBOUNCE_TIME_FOR_SCROLL_REQUESTS = 2000;

// Would use IntersectionObserver but it's not supported in IE
export function isElementInViewport(elem: HTMLElement) {
	const elementBounds = elem.getBoundingClientRect();

	return (
		elementBounds.top >= 0 &&
		elementBounds.left >= 0 &&
		elementBounds.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
		elementBounds.right <= (window.innerWidth || document.documentElement.clientWidth)
	);
}

export const getElementFromHash = (linkHash: string) => {
	// remove # in first position if it's there.
	let elId = linkHash.replace(/^#/, '');

	// Support unicode characters. TinyMCE expects to see them as actual
	// characters (not encoded).
	try {
		elId = decodeURIComponent(elId);
	} catch (e) {
		// NOOP
	}

	// Find by ID or fallback to name
	return document.getElementById(elId) || document.querySelector(`[name="${CSS.escape(elId)}"]`);
};

export const followHashAnchorIfNeeded = (
	observee: HTMLElement,
	scrollDebounceTimerHandle?: number,
) => {
	if (scrollDebounceTimerHandle !== 0) {
		window.clearTimeout(scrollDebounceTimerHandle);
	}

	scrollDebounceTimerHandle = window.setTimeout(
		(observee: HTMLElement) => {
			if (observee) {
				observee.scrollIntoView();
			}

			scrollDebounceTimerHandle = 0;
		},
		DEBOUNCE_TIME_FOR_SCROLL_REQUESTS,
		observee,
	);
};
