import { requireLegacyWRM, loadResources } from '@confluence/wrm';

export function isRequiredModuleLoaded(module) {
	try {
		window.require(module);
		return true;
	} catch (err) {
		return false;
	}
}

/**
 * Load JS dependencies and inject them to the script container
 * Note: Load JS resources only when superbatch has been loaded
 *
 * @param resourceKeys Web resource keys
 * @param jsTags JS tags
 * @param scriptContainer The DOM element that will contain the JS script tags
 * @returns {Promise}
 */
export const loadJSDependencies = (
	resourceKeys: string[],
	jsTags: string,
	scriptContainer: HTMLElement,
): Promise<void> => {
	if (!scriptContainer) {
		return Promise.reject(new Error('script container is not present'));
	}

	if (resourceKeys.length) {
		return new Promise((resolve) => {
			requireLegacyWRM(
				resourceKeys,
				() => {
					void loadResources(scriptContainer, jsTags).then(resolve);
				},
				() => {
					if (process.env.NODE_ENV !== 'production') {
						// eslint-disable-next-line no-console
						console.error('Cannot load resources because WRM is not available');
					}
				},
			);
		});
	} else {
		return loadResources(scriptContainer, jsTags);
	}
};
